<template>
  <v-container
    id="subscribe"
    tag="section"
    :class="ShowMainPage === 'Y' ? 'pa-0 custom-bg' : 'pa-0'"
    fluid
    fill-height
  >
    <!-- color="rgba(236, 6, 141, 1)" -->
    <!-- <base-card
      class="pt-0"
    >
      <v-container> -->
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="5"
          >
            <base-subheading class="mb-3" dark>
              <span class="display-3"><strong>{{ $t('MeeTeeMeeNgern1.Subscribe') }}</strong></span>
              <v-divider></v-divider>
            </base-subheading>

            <v-row row wrap>
              <v-col cols="12" class="pt-0 pb-2 mb-0" lg="12">
                <v-text-field
                  class="bg-editor"
                  dense hide-details
                  v-model="NameSurName"
                  outlined>
                  <template v-slot:label>
                    <span class="input__label text-body-1">{{ $t('MeeTeeMeeNgern1.NameSurName') }}</span>
                    <span class="red--text text-body">*</span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pt-0 pb-2 mb-0" lg="12">
                <v-text-field
                  class="bg-editor"
                  dense hide-details 
                  v-model="email"
                  outlined>
                  <template v-slot:label>
                    <span class="input__label text-body-1">{{ $t('MeeTeeMeeNgern1.email') }}</span>
                    <span class="red--text text-body">*</span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pt-0 pb-2 mb-0" lg="12">
                <v-text-field
                  class="bg-editor"
                  dense hide-details
                  v-model="tel"
                  outlined>
                  <template v-slot:label>
                    <span class="input__label text-body-1">{{ $t('MeeTeeMeeNgern1.Tel') }}</span>
                    <span class="red--text text-body">*</span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pt-0 pb-2 mb-0" lg="12">
                <v-textarea
                  class="bg-editor"
                  dense
                  v-model="subscribeHeader"
                  hide-details
                  outlined
                  rows="1"
                >
                <template v-slot:label>
                  <span class="input__label text-body-1">เรื่องที่ต้องการติดต่อ</span>
                  <span class="red--text text-body">*</span>
                </template>
                </v-textarea>
              </v-col>
              <v-col cols="12" class="pt-0 pb-2 mb-0" lg="12">
                <v-textarea
                  class="bg-editor"
                  dense
                  v-model="subscribeText"
                  hide-details
                  outlined
                  rows="4"
                >
                <template v-slot:label>
                  <span class="input__label text-body-1">{{ $t('MeeTeeMeeNgern1.message') }}</span>
                  <span class="red--text text-body">*</span>
                </template>
                </v-textarea>
              </v-col>
              <v-col cols="12" class="pt-0 pb-2 mb-0" lg="12">
                <!-- <v-radio-group v-model="PolicyFlag" row dense hide-details>
                  <v-radio class='text--primary'
                    value="Y"
                  >
                    <template v-slot:label>
                      <div><span class='text--primary'>{{ $t('MeeTeeMeeNgern1.AcceptPolicy') }}</span></div>
                    </template>
                  </v-radio>
                  <v-radio
                    value="N"
                  >
                    <template v-slot:label>
                      <div><span class='text--primary'>{{ $t('MeeTeeMeeNgern1.RejectPolicy') }}</span></div>
                    </template>
                  </v-radio>
                </v-radio-group> -->
                <v-card     
                  class="my-2 pa-0"
                  outlined
                  :style="'border: 1px solid pink;'"
                  dense
                  flat
                >
                  <v-card-text dense flat class="text--primary">
                  <v-btn dark
                    dense 
                    flat
                    text
                    class="text-body-1 ma-0 pa-0"
                    color="pink"
                    @click="CookieLaw"
                  >{{ $t('Register.messagePolicy') }}
                  </v-btn>
                  <v-divider class="ma-0 pa-0" inset vertical></v-divider>
                  <v-checkbox class='text--primary'
                    value="Y"
                    v-model="consentFlag"
                    dense flat
                  >
                    <template v-slot:label>
                      <div><span class='text--primary display-1'>{{ $t('Register.Policy') }}</span></div>
                    </template>
                  </v-checkbox>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col align="center" cols="12" class="pt-0 pb-2 mb-0" lg="12">
                <v-btn dark
                  depressed
                  class="ma-0"
                  color="pink"
                  @click="addSubscribe"
                >{{ $t('MeeTeeMeeNgern1.send') }}
                </v-btn>
                <!-- <v-divider class="mx-1" inset vertical></v-divider> 
                <v-btn dark
                  small
                  text
                  color="pink"
                  @click="CookieLaw"
                >{{ $t('MeeTeeMeeNgern1.messagePolicy') }}
                </v-btn> -->
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="1"></v-col>  -->
          <v-col
            cols="12"
            md="7"
            class="pl-10"
          >
            <v-card
              flat
              max-width="800"
              class="mx-auto"
            >
              <v-list 
                :class="ShowMainPage === 'Y' ? 'transparent pl-0 custom-bg' : 'transparent pl-0'">
                <v-list-item>
                  <v-list-item-avatar
                    color="pink"
                  >
                    <v-icon
                      class="elevation-4"
                      dark
                    >
                      mdi-email-outline
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="pl-0">
                    <v-list-item-title><span class="display-1">{{ $t('MeeTeeMeeNgern1.EMAIL') }}:</span></v-list-item-title>
                    <v-list-item-subtitle class="body-1 black--text text-wrap">MKT@meetee.co.th</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-avatar color="pink">
                    <v-icon
                      class="elevation-4"
                      dark
                    >
                      mdi-map-marker
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title><span class="display-1">{{ $t('MeeTeeMeeNgern1.ADDRESS') }}:</span></v-list-item-title>
                    <!-- <v-list-item-subtitle><span class="body-2">{{ $t('MeeTeeMeeNgern1.ADDRESSDetail') }}</span></v-list-item-subtitle>
                    <v-list-item-subtitle><span class="body-2">{{ $t('MeeTeeMeeNgern1.ADDRESSDetail2') }}</span></v-list-item-subtitle> -->
                    <v-list-item-subtitle class="body-1 black--text text-wrap" v-html="$t('MeeTeeMeeNgern1.ADDRESSDetail')"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-avatar color="pink">
                    <v-icon
                      class="elevation-4"
                      dark
                    >
                      mdi-phone
                    </v-icon>
                  </v-list-item-avatar>

                  <!-- <v-list-item-title><span class="display-1">PHONE</span></v-list-item-title>

                  <v-list-item-subtitle>
                    <span class="display-1">555-789-1234</span>
                  </v-list-item-subtitle> -->
                  <v-list-item-content>
                    <v-list-item-title><span class="display-1">{{ $t('MeeTeeMeeNgern1.PHONE') }}:</span></v-list-item-title>
                    <v-list-item-subtitle class="body-1 black--text text-wrap">02-025-6999</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <a href="https://goo.gl/maps/dc12SLiy48CYvuDu9" target="_blank">
                <img src="../../assets/MeeTeeMeeNgern/map.png" width="100%" height="100%"/>
              </a>
              <!-- <div class="indent50 display-2 pink--text">
                <img src="../../assets/MeeTeeMeeNgern/map.png" height="45%" weight="20%"/>
              </div> -->
            </v-card>
            <!-- <v-card
              flat
              class="mx-auto"
            >
              <v-img src="../../assets/MeeTeeMeeNgern/map.png" width="100%" height="100%"></v-img>
            </v-card> -->
          </v-col>
          <v-col
            cols="12"
            md="12"
            class="pb-10"
            v-if="ShowMainPage === 'NotShow'"
          >
          <!-- v-if="ShowMainPage !== 'Y'" -->
            <base-subheading class="mb-3" dark>
              <span class="display-3"><strong>{{ $t('MeeTeeMeeNgern1.FAQ') }}</strong></span>
              <v-divider></v-divider>
            </base-subheading>

            <v-row row wrap>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <span class="display-2">{{ $t('MeeTeeMeeNgern1.Question1') }}</span>
                    <template v-slot:actions>
                      <v-icon color="error">
                        $expand
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <span class="display-1 pink--text">{{ $t('MeeTeeMeeNgern1.Answer1') }}</span>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    <span class="display-2">{{ $t('MeeTeeMeeNgern1.Question2') }}</span>
                    <template v-slot:actions>
                      <v-icon color="error">
                        $expand
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <span class="display-1 pink--text">{{ $t('MeeTeeMeeNgern1.Answer2') }}</span>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    <span class="display-2">{{ $t('MeeTeeMeeNgern1.Question3') }}</span>
                    <template v-slot:actions>
                      <v-icon color="error">
                        $expand
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <span class="display-1 pink--text">{{ $t('MeeTeeMeeNgern1.Answer3') }}</span>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    <v-dialog
      v-model="dialog.dialogPolicy"
      width="800px"
    >
      <v-card>
        <!-- <v-card-title>
          <span class="text-h5">Use Google's location service?</span>
        </v-card-title> -->
        <v-card-text>
          <cookiepolicy></cookiepolicy>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            dark
            text
            @click="dialog.dialogPolicy = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  export default {
    name: 'HomeSubscribe',
    props: {
      limitShow: {
        type: Number,
        required: false,
      },
      ShowMainPage: {
        type: String,
        required: false,
      },
    },
    data () {
      return {
        utils: require('@/utils'),
        stringFormat: require('string-format'),
        appName: process.env.VUE_APP_DB_APP_NAME,
        dialog: {
          dialogPolicy: false,
        },
        NameSurName: '',
        PolicyFlag: '',
        subscribeText: '',
        subscribeHeader: '',
        email: '',
        tel: '',
        ipAddress: '',
        consentFlag: '',
        reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      }
    },
    components: {
      cookiepolicy: () => import('@/components/cookie-policy'),
    },
    async created () {
      this.utils.UtilsAddVisitCountMenu(this.currentRouteName, 0) /* 1 visit count menu */
    },
    computed: {
      currentRouteName () { /* 3 visit count menu */
        return this.$route.name
      }
    },
    methods: {
      initialize () {
        this.NameSurName = ''
        this.PolicyFlag = ''
        this.subscribeText = ''
        this.email = ''
        this.tel = ''
        this.ipAddress = ''
        this.subscribeHeader = ''
        this.consentFlag = ''
      },
      CookieLaw () {
        this.dialog.dialogPolicy = true
      },
      test () {
        console.log('test', '')
      },
      async addSubscribe () {
        if (!this.NameSurName) {
          this.$swal({type: 'warning', title: this.$t('SubscribeMaint.messageName')})
          return
        }
        if (!this.email) {
          this.$swal({type: 'warning', title: this.$t('SubscribeMaint.messageEmail')})
          return
        }
        if (this.email) {
          if (!this.reg.test(this.email)) {
            this.$swal({type: 'warning', title: 'อีเมลไม่ถูกต้อง'})
            return
          }
        }
        if (!this.tel) {
          this.$swal({type: 'warning', title: this.$t('SubscribeMaint.messageTel')})
          return
        }
        if (!this.subscribeHeader) {
          this.$swal({type: 'warning', title: this.$t('SubscribeMaint.messageSubscribeHeaderMessage')})
          return
        }
        if (!this.subscribeText) {
          this.$swal({type: 'warning', title: this.$t('SubscribeMaint.messageSubscribeMessage')})
          return
        }
        if (!this.consentFlag) {
          this.$swal({type: 'warning', title: this.$t('SubscribeMaint.messagePolicy')})
          return
        }
        await this.axios.post(process.env.VUE_APP_API + '/subscribe/addSubscribe' + process.env.VUE_APP_DATABASE_TYPE, {
          name: this.NameSurName,
          policy_flag: this.consentFlag,
          subscribe_text: this.subscribeText,
          email: this.email,
          tel: this.tel,
          ip_address: this.ipAddress,
          subscribe_header: this.subscribeHeader,
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.initialize()
              this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
    }
  }
</script>
<style scoped>
  .bg-editor {
    /* `!important` is necessary here because Vuetify overrides this */
    background: rgb(255, 255, 255) !important;
  }
  .input__label {
    color: black;
  }
  .custom-bg {
  /* `!important` is necessary here because Vuetify overrides this */
    background: rgb(242, 245, 247) !important;
    /* background: url('../../assets/bg/bg1.jpg');
    background-size: cover; */
  }
</style>